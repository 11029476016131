import { getItemFromLS, setItemToLS, removeFromLS } from "../functions";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import * as userPoolConfig from "../config";
import { router } from "../router/index";
import axios from "../axios";

const initialState = {
  token: "",
  refreshToken: "",
  idToken: "",
  isAuthenticated: false,
  userData: { username: "", email: "", password: "", userId: "" },
  loading: false,
  errMsg: "",
  isError: false,
};

export const state = () => ({
  ...initialState,
  token: getItemFromLS("access_token") || initialState.token,
  refreshToken: getItemFromLS("refresh_token") || initialState.refreshToken,
  idToken: getItemFromLS("id_token") || initialState.idToken,
});

export const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  token: (state) => state.token,
  loading: (state) => state.loading,
  isError: (state) => state.isError,
  errMsg: (state) => state.errMsg,
  userData: (state) => state.userData,
};

export const mutations = {
  setToken(state, { accessToken, idToken, refreshToken }) {
    Object.assign(state, {
      token: accessToken,
      idToken,
      refreshToken,
      isAuthenticated: true,
    });
    setItemToLS("access_token", accessToken);
    setItemToLS("id_token", idToken);
    setItemToLS("refresh_token", refreshToken);
  },
  clearAuthData(state) {
    Object.assign(state, { ...initialState });
    removeFromLS("access_token");
    removeFromLS("id_token");
    removeFromLS("refresh_token");
  },
  updateState(state, payload) {
    Object.assign(state, payload);
  },
};

export const actions = {
  async logout({ commit }) {
    const userPool = new CognitoUserPool(userPoolConfig.poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut();
    }
    commit("clearAuthData");
  },

  async setTokens({ commit }, tokens) {
    commit("setToken", tokens);
  },

  async refreshToken({ commit }) {
    const userPool = new CognitoUserPool(userPoolConfig.poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      throw new Error("User not found");
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) return reject(err);
        if (!session.isValid()) return reject("Session not valid");

        const tokens = {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
        };
        commit("setToken", tokens);
        resolve(session);
      });
    });
  },

  async registerAdminUser({ state }) {
    const params = {
      userId: state.userData.userId,
      userName: state.userData.username,
      firstName: state.userData.username,
      lastName: "",
      emailAddress: state.userData.email,
      status: "active",
    };

    await axios
      .post("main/admin/user", params)
      .then((res) => {
        console.log("register admin =========>", res);
        router.push("/");
      })
      .catch((err) => {
        console.log("err", err);
      });
  },

  async signIn({ commit, dispatch, state }) {
    const authenticationDetails = new AuthenticationDetails({
      Username: state.userData.email,
      Password: state.userData.password,
    });

    const userPool = new CognitoUserPool(userPoolConfig.poolData);
    const userData = { Username: state.userData.email, Pool: userPool };

    const userAuth = new CognitoUser(userData);

    commit("updateState", { loading: true, isError: false, errMsg: "" });

    return new Promise((resolve, reject) => {
      userAuth.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          const tokens = {
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
          };
          commit("setToken", tokens);
          commit("updateState", { loading: false });

          dispatch("registerAdminUser");
        },
        onFailure: (err) => {
          commit("updateState", {
            loading: false,
            isError: true,
            errMsg: err.message,
          });
          router.push("/confirm-email");
        },
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
