import MainMenu from "@/components/MainMenu";
import SignIn from "@/components/auth/SignIn";
import BlankCanvas from "@/components/BlankCanvas";
import PreviousCanvas from "@/components/PreviousCanvas";
import LooseThoughts from "@/components/LooseThoughts";
import ConfirmEmail from "@/components/auth/ConfirmEmail.vue";
import ForgotPassword from "@/components/auth/ForgotPassword";
import ResetPassword from "@/components/auth/ResetPassword";
import { getItemFromLS } from "../functions";
import { createRouter, createWebHistory } from "vue-router";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "MainMenu",
      component: MainMenu,
      beforeEnter: (to, from, next) => {
        const tokenExists = getItemFromLS("access_token");
        const hasCodeQuery = to.query.code;

        if (!tokenExists && !hasCodeQuery) {
          next({ name: "SignIn" });
        } else {
          next();
        }
      },
    },
    {
      path: "/sign-in",
      name: "SignIn",
      component: SignIn,
    },
    {
      path: "/blank-canvas",
      name: "BlankCanvas",
      component: BlankCanvas,
    },
    {
      path: "/previous-canvas/:id",
      name: "PreviousCanvas",
      component: PreviousCanvas,
    },
    {
      path: "/loose-thoughts",
      name: "LooseThoughts",
      component: LooseThoughts,
    },
    {
      path: "/confirm-email",
      name: "ConfirmEmail",
      component: ConfirmEmail,
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
    }
  ],
});

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('previousUrl', from.fullPath);
  next();
})
