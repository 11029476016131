export const setItemToLS = (itemKey, body) => {
  return localStorage.setItem(itemKey, JSON.stringify(body));
};

export const getItemFromLS = (itemKey) => {
  let ItemFromLs = localStorage.getItem(itemKey);
  return (ItemFromLs = JSON.parse(ItemFromLs));
};

export const removeFromLS = (itemKey) => {
  localStorage.removeItem(itemKey);
};

export const convertToPercentage = (x, y) => {
  if (typeof x != "number") x = 0;
  if (typeof y != "number") y = 0;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  let PercentageX = (x / screenWidth) * 100;
  let PercentageY = (y / screenHeight) * 100;

  let result = {
    x: +PercentageX.toFixed(10),
    y: +PercentageY.toFixed(10),
  };

  return result;
};

export const convertToPixeles = (x, y) => {
  if (typeof x != "number") x = 0;
  if (typeof y != "number") y = 0;

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  let PercentageX = screenWidth * (x / 100);
  let PercentageY = screenHeight * (y / 100);

  let result = {
    x: Math.round(PercentageX),
    y: Math.round(PercentageY),
  };

  return result;
};

export const getPositionForKO = (KOItem) => {
  let koLocations = getItemFromLS("koLocations");
  let filteredKOsPosition = koLocations.find(
    (el) => el.id === KOItem.knowledgeObjectId
  );
  return filteredKOsPosition ? filteredKOsPosition : { x: 0, y: 0 };
};

export const getValidateHashValue = (hashInputValue) => {
  if (hashInputValue.search(/ $/) !== -1)
    hashInputValue = hashInputValue.substr(0, hashInputValue.length - 1);
  const splitHashArray = hashInputValue.split(" ");
  const updatedSplitHashArray = splitHashArray.map((el) => {
    if (el[0] !== "#") {
      // +
      let splitWord = el.split(""); // +
      const shiftedLetter = splitWord.shift().toUpperCase();
      splitWord.splice(0, 0, shiftedLetter);
      splitWord = splitWord.join(""); // +
      el = splitWord;
    }
    return el;
  });
  return updatedSplitHashArray.join("");
};
export const validateHashTag = (event, hashInput) => {
  const isEmptyValue = hashInput.value.replaceAll(/[\s]/g, "").length === 0;
  const isInvalidLetters =
    hashInput.value.match(/^[a-z0-9 ]*/g)[0].length !== hashInput.value.length;
  const isInvalidSpaceLetter = hashInput.value.match(/ {2}/g);

  if (isEmptyValue || isInvalidLetters || isInvalidSpaceLetter) {
    event.preventDefault();
    hashInput.classList.add("invalid-input");
  } else {
    hashInput.classList.remove("invalid-input");
  }

  if (hashInput.value === "") hashInput.classList.remove("invalid-input");
};

export const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return [h, s, l];
};

export const hexToRGB = (h) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return [r, g, b];
};

export const hexToHsl = (hex) => {
  let rgb = hexToRGB(hex);
  return rgbToHsl(rgb[0], rgb[1], rgb[2]);
};

export const updatePositionKo = (id, KO) => {
  let koLocations = getItemFromLS("koLocations");
  if (!koLocations) koLocations = [];
  koLocations.forEach((el) => {
    if (el.id === id) {
      el.x = KO.x;
      el.y = KO.y;
    }
  });
  setItemToLS("koLocations", koLocations);
};

export const filterKOPosition = () => {
  const KOItems = getItemFromLS("KOItems");
  let koLocationsOld = getItemFromLS("koLocations");
  if (!koLocationsOld) koLocationsOld = [];
  let koLocations = [];

  KOItems.map((KOItem) => {
    let findItem = koLocationsOld.find(
      (el) => el.id === KOItem.knowledgeObjectId
    );
    if (findItem) koLocations.push(findItem);
  });
  setItemToLS("koLocations", koLocations);
};
