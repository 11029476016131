<template>
  <b-overlay
    :show="stateOverlayForCanvas || stateOverlayForKO"
    id="previous-canvas"
  >
    <CanvasHeader
      :pageType="'previousCanvas'"
      :commitKO="commitKO"
      :deleteCanvas="deleteCanvas"
    />
    <img
      class="previous-canvas__img-top"
      src="../assets/for-items-top.png"
      alt=""
      @click="toggleNewThought"
    />
    <div v-if="previousCanvas.canvasName" class="previous-canvas__container">
      <!-- <h2
        class="previous-canvas__container-name"
        v-b-popover.hover.top="previousCanvas.canvasName"
      >
        {{ previousCanvas.canvasName }}
      </h2> -->
      <div class="previous-canvas__list">
        <Vue3DraggableResizable
          :initW="0"
          :initH="0"
          :draggable="true"
          :resizable="false"
          :active="true"
          v-on:dragging="movedKO"
          :x="convertToPixeles(getPositionForKO(item).x, 0).x"
          :y="convertToPixeles(0, getPositionForKO(item).y).y"
          @drag-end="dragStop(item.knowledgeObjectId)"
          v-for="item in previousCanvas.KOs"
          :key="item.knowledgeObjectId"
          class="previous-canvas__list-item"
          :parent="true"
        >
          <KOItem
            :body="item.body"
            :deleteKO="deleteKo"
            :updateKo="updateKO"
            :metatags="item.metatags"
            :type="'blank-canvas'"
            :knowledge-object-id="item.knowledgeObjectId"
          />
        </Vue3DraggableResizable>
      </div>
      <TextEntryBox
        v-if="isOpenAddingBlock"
        :type="'previousCanvas'"
        :KOForUpdate="KOForUpdate"
        :previousCanvasId="this.$route.params.id"
        :isCommitKO="isCommitKO"
        :addingBlockPosition="addingBlockPosition"
      />
      <ul class="previous-canvas__link-list">
        <li class="link-item">
          <button class="parking-btn">{{ previousCanvas.canvasName }}</button>
        </li>
      </ul>
    </div>
    <div id="darken-block" v-if="IsOpenNewThought" @click="toggleNewThought" />
    <NewLooseThought
      class="previous-canvas__new-thought-dropdown"
      v-if="IsOpenNewThought"
      :toggleNewThought="toggleNewThought"
    />
  </b-overlay>
</template>

<script>
  import { mapGetters } from "vuex";
  import TextEntryBox from "./TextEntryBox.vue";
  import {
    convertToPercentage,
    convertToPixeles,
    getItemFromLS,
    getPositionForKO,
    setItemToLS,
    updatePositionKo,
  } from "../functions";
  import Vue3DraggableResizable from "vue3-draggable-resizable";
  import CanvasHeader from "./PreviousBlankHeader.vue";
  import NewLooseThought from "./NewLooseThoughts";
  import KOItem from "./KOItem";

  export default {
    name: "PreviousCanvas",
    data() {
      return {
        isPossibilityUpdateKo: true,
        isOpenAddingBlock: false,
        addingBlockPosition: {
          top: null,
          left: null,
        },
        isOpenUpdateKo: false,
        IsOpenNewThought: false,
        KOForUpdate: {},
        KOForDelete: [],
        isCommitKO: false,
        top: 0,
        left: 0,
        isDeletingCanvas: false,
        movedItem: false,
      };
    },
    computed: {
      ...mapGetters({
        previousCanvas: "canvasStore/previousCanvas",
        createdKOId: "KOStore/createdKOId",
        stateOverlayForCanvas: "canvasStore/stateOverlayForCanvas",
        stateOverlayForKO: "KOStore/stateOverlayForKO",
      }),
    },
    components: {
      TextEntryBox,
      CanvasHeader,
      NewLooseThought,
      Vue3DraggableResizable,
      KOItem,
    },
    created() {
      window.addEventListener("beforeunload", this.updateCanvas);
    },
    mounted() {
      const canvasPlace = document.getElementById("previous-canvas");
      canvasPlace.addEventListener("click", this.clickListener);
      this.$store.dispatch(`canvasStore/getCanvas`, this.$route.params.id);

      const updateInterval = 300000;
      this.canvasInterval = setInterval(() => {
        this.updateCanvas();
      }, updateInterval);
    },
    watch: {
      createdKOId() {
        this.updateKoOnCanvas(this.createdKOId, "add");
      },
    },
    methods: {
      setItemToLS,
      convertToPixeles,
      convertToPercentage,
      getPositionForKO,
      toggleAddingBlock() {
        this.isOpenAddingBlock = true;

        if (this.isOpenUpdateKo) {
          this.isOpenUpdateKo = false;
          this.isOpenAddingBlock = false;
        }
        // this.isOpenAddingBlock = !this.isOpenAddingBlock;
      },
      clickListener(e) {
        if (e.target.className.indexOf)
          if (this.isClickOkayForOpenTextEntryBox(e)) {
            this.addingBlockPosition.top = e.clientY;
            this.addingBlockPosition.left = e.clientX;
            this.toggleAddingBlock();
            this.IsOpenNewThought = false;
          }
        if (e.target.className.indexOf("main-adding-block__save") !== -1)
          this.isOpenAddingBlock = false;
        this.isPossibilityUpdateKo = true;
      },
      async deleteCanvas() {
        await this.$store.dispatch(
          "KOStore/deleteKOItems",
          this.previousCanvas.KOs
        );
        await this.$store.dispatch(
          `canvasStore/deleteCanvas`,
          this.$route.params.id
        );
        this.isDeletingCanvas = true;
        await this.$router.push("/");
      },
      async updateKoOnCanvas(id, updateType) {
        const updatedCanvas = {
          canvasName: this.previousCanvas.canvasName,
          koUpdates: [{ id, updateType }],
          koLocations: JSON.stringify(getItemFromLS("koLocations")),
        };
        this.setItemToLS("updatedCanvas", updatedCanvas);
        await this.$store.dispatch(
          `canvasStore/updateCanvas`,
          this.$route.params.id
        );
      },
      async updateCanvas() {
        const updatedCanvas = {
          canvasName: this.previousCanvas.canvasName,
          koUpdates: [],
          koLocations: JSON.stringify(getItemFromLS("koLocations")),
        };
        this.setItemToLS("updatedCanvas", updatedCanvas);
        await this.$store.dispatch(
          `canvasStore/updateCanvas`,
          this.previousCanvas.userCanvasId
        );
        setItemToLS("KOItems", []);
        this.$store.commit("KOStore/setKOItems", []);
      },
      commitKO() {
        this.isCommitKO = true;
      },
      updateKO(itemKO) {
        let koLocations = getItemFromLS("koLocations").find(
          (el) => el.id === itemKO.knowledgeObjectId
        );
        let itemPosition = { x: 0, y: 0 };

        if (koLocations) itemPosition = koLocations;

        if (itemPosition.y && itemPosition.x) {
          this.top = itemPosition.y;
          this.left = itemPosition.x;
          itemKO.left = itemPosition.x;
          itemKO.top = itemPosition.y;
          this.addingBlockPosition = {
            left: convertToPixeles(itemPosition.x + 0.8, 0).x,
            top: convertToPixeles(0, itemPosition.y + 14.2).y,
          };
        }
        this.KOForUpdate = itemKO;

        this.isOpenAddingBlock = !this.isOpenAddingBlock;
        this.isOpenUpdateKo = true;
        setTimeout(() => {
          this.KOForUpdate = {};
        }, 500);
      },
      async deleteKo(id) {
        await this.$store.dispatch(`KOStore/deleteKOItem`, id).then(() => {
          this.previousCanvas.KOs = this.previousCanvas.KOs.filter(
            (el) => el.knowledgeObjectId !== id
          );
          let koLocations = getItemFromLS("koLocations");
          koLocations = koLocations.filter((el) => el.id !== id);
          setItemToLS("koLocations", koLocations);
          this.updateKoOnCanvas(id, "remove");
        });
      },
      movedKO(newRect) {
        this.top = newRect.y;
        this.left = newRect.x;
        this.movedItem = true;
      },
      dragStop(knowledgeObjectId) {
        let position = this.convertToPercentage(this.left, this.top);
        let updateKO = this.previousCanvas.KOs.filter(
          (elem) => String(elem.knowledgeObjectId) === String(knowledgeObjectId)
        )[0];
        let newKo = {
          y: position.y,
          x: position.x,
          body: updateKO.body,
          metatags: updateKO.metatags,
        };

        if (this.isPossibilityUpdateKo && this.movedItem) {
          updatePositionKo(knowledgeObjectId, position);
          this.setItemToLS("updatedKO", newKo);
        }

        this.movedItem = false;
        this.isPossibilityUpdateKo = false;
      },
      toggleNewThought() {
        this.IsOpenNewThought = !this.IsOpenNewThought;
        if (this.isOpenAddingBlock) this.isOpenAddingBlock = false;
      },
      isClickOkayForOpenTextEntryBox(e) {
        return (
          (e.target.className.indexOf("previous-canvas") !== -1 ||
            e.target.className.indexOf("main-adding-block__save") !== -1) &&
          e.target.className.indexOf("__container") === -1 &&
          e.target.className.indexOf("__img-top") === -1
        );
      },
    },
    beforeUnmount() {
      if (!this.isDeletingCanvas) this.updateCanvas();
      clearInterval(this.canvasInterval);
    },
  };
</script>

<style scoped lang="scss">
  #previous-canvas {
    position: relative;
    min-height: 100vh;
    overflow-y: hidden;
    overflow-y: hidden;
    .previous-canvas__container {
      padding-top: 30px;
      padding-bottom: 50px;
      overflow-y: hidden;

      &-name {
        font-size: 40px;
        padding: 5px 30px;
        width: fit-content;
        max-width: 60vw;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        background-color: rgba(165, 185, 212, 0.24);
        box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.24),
          0 4px 7px 2px rgba(0, 0, 0, 0.19);
        max-height: 55px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .previous-canvas {
        &__list {
          margin-top: 20px;
          margin-left: 1vw;
          position: relative;
          min-height: 76vh;
          max-width: 97vw;

          &-item {
            position: absolute;
            color: rgb(2, 2, 2);
            border-radius: 4px;
            margin-right: 15px;

            &::before {
              outline: none;
            }
          }
        }

        &__link-list {
          position: absolute;
          bottom: 0.5px;
          right: 0;
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }

    .previous-canvas {
      &__img-top {
        display: block;
        width: 280px;
        position: absolute;
        z-index: 30;

        &:hover {
          cursor: pointer;
        }
      }

      &__new-thought-dropdown {
        position: absolute;
        animation-duration: 300ms;
        animation-name: slidein;
        animation-iteration-count: 1;
        z-index: 30;
      }
    }
  }

  .link-item {
    width: 280px;
    height: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .parking-btn {
    background-image: url("../assets/for-items-bottom.png");
    background-color: transparent;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    border: none;
    background-size: cover;
    font-size: 14px;
    z-index: 30;
    position: absolute;
    right: 1px;
    bottom: 1px;
    &:hover {
      cursor: pointer;
    }
  }

  @keyframes slidein {
    from {
      opacity: 0;
      top: -330px;
    }

    to {
      opacity: 1;
      top: 63px;
    }
  }

  @media (min-width: 1200px) {
    #previous-canvas {
      .previous-canvas__container {
        &-name {
          font-size: 35px;
          padding: 5px 25px;
        }
      }

      .previous-canvas {
        &__new-thought-dropdown {
          top: 63px;
        }
      }
    }

    @keyframes slidein {
      from {
        opacity: 0;
        top: -330px;
      }

      to {
        opacity: 1;
        top: 80px;
      }
    }
  }

  @media (max-width: 1200px) {
    #previous-canvas {
      .previous-canvas__container {
        &-name {
          font-size: 35px;
          padding: 5px 25px;
        }
      }

      .previous-canvas {
        &__new-thought-dropdown {
          top: 63px;
        }
      }
    }

    @keyframes slidein {
      from {
        opacity: 0;
        top: -330px;
      }

      to {
        opacity: 1;
        top: 80px;
      }
    }
  }

  @media (max-width: 992px) {
    #previous-canvas {
      .previous-canvas__container {
        &-name {
          font-size: 30px;
          padding: 5px 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    #previous-canvas {
      .previous-canvas__container {
        &-name {
          font-size: 25px;
          padding: 3px 20px;
        }
      }

      .previous-canvas {
        &__img-top {
          z-index: 20;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .previous-canvas__img-top {
      width: 40px;
    }
  }

  @media (max-width: 576px) {
    #previous-canvas {
      .previous-canvas__container {
        &-name {
          font-size: 20px;
          padding: 2px 15px;
        }
      }
    }
  }
</style>
