export const poolData = {
  UserPoolId: "us-west-2_VbZXhwAbZ",
  ClientId: "1sfe3gemecsobbkq3kmvssg3mf",
};

export const adminEmailList = [
  "blake.watkins@iinet.net.au",
  "brad@prevoke-inc.com",
  "kmsynapse@gmail.com",
  "anatolii.blashkiv@outlook.com"
]
