import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import "normalize.css";

import store from "./store";
import BootstrapVueNext from "bootstrap-vue-next";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

const app = createApp(App);
app.use(router);
app.use(store);

app.use(Vue3DraggableResizable);
app.use(BootstrapVueNext);
app.mount("#app");
