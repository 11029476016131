<template>
  <div class="loose-thoughts">
    <div class="loose-thoughts__top-block">
      <button class="top_block__home-btn" type="button" @click="redirectHome">
        Home
      </button>
    </div>
    <img
      class="loose-thoughts__img-top"
      src="../assets/for-items-top.png"
      v-on:click="toggleNewThought"
    />
    <div id="darken-block" v-if="IsOpenNewThought" @click="toggleNewThought" />
    <NewLooseThoughts
      id="testNewLooseThought"
      v-if="this.IsOpenNewThought"
      :toggle-new-thought="toggleNewThought"
      :isUpdateLooseThought="isUpdateLooseThought"
    />
    <b-overlay :show="stateOverlay" id="loose-thought-body">
      <h2 class="loose-thoughts__title">My Loose Thoughts</h2>
      <ul class="loose-thoughts__list">
        <li
          class="loose-thoughts__list-item"
          v-for="item in this.looseThoughts"
          :key="item.index"
        >
          <button
            type="button"
            class="list-item__btn-add list-item__btn"
            v-on:click="openInNewCanvas(item)"
          />
          <button
            type="button"
            class="list-item__btn-save list-item__btn"
            v-on:click="commitLooseThoughts(item)"
          />
          <button
            type="button"
            class="list-item__btn-delete list-item-btn"
            @click="deleteLooseThought(item.knowledgeObjectId)"
          >
            <i class="bi bi-trash"></i>
          </button>
          <p class="loose-thoughts__list-item-body">{{ item.body }}</p>
          <ul class="list-item__metatags-list">
            <li
              class="list-item__metatags-list-item"
              v-for="metatag in item.metatags"
              :key="metatag.index"
            >
              {{ metatag }}
            </li>
          </ul>
        </li>
      </ul>
    </b-overlay>
    <!-- <img
      class="loose-thoughts__img-bottom"
      src="../assets/for-items-bottom.png"
      alt=""
    /> -->
  </div>
</template>

<script>
  import { setItemToLS, hexToRGB } from "../functions";
  import { mapGetters } from "vuex";
  import NewLooseThoughts from "./NewLooseThoughts";

  export default {
    name: "LooseThoughts",
    data() {
      return {
        looseThoughtsItems: [],
        colorArray: [
          "#FFC0CB",
          "#FFB6C1",
          "#FF69B4",
          "#FF1493",
          "#DB7093",
          "#C71585",
          "#BA55D3",
          "#9932CC",
          "#9400D3",
          "#8A2BE2",
          "#8B008B",
          "#483D8B",
          "#4B0082",
          "#FFA07A",
          "#FA8072",
          "#E9967A",
          "#CD5C5C",
          "#DC143C",
          "#FF0000",
          "#B22222",
          "#8B0000",
          "#FFA500",
          "#FF8C00",
          "#FF7F50",
          "#FF6347",
          "#FF4500",
          "#FFD700",
          "#FFFF00",
          "#FFFACD",
          "#FFEFD5",
          "#FFE4B5",
          "#FFDAB9",
          "#EEE8AA",
          "#F0E68C",
          "#BDB76B",
          "#ADFF2F",
          "#00FF00",
          "#00FA9A",
          "#00FF7F",
          "#3CB371",
          "#008000",
          "#006400",
          "#9ACD32",
          "#556B2F",
          "#66CDAA",
          "#20B2AA",
          "#008B8B",
          "#00FFFF",
          "#AFEEEE",
          "#7FFFD4",
          "#40E0D0",
          "#00CED1",
          "#5F9EA0",
          "#4682B4",
          "#87CEFA",
          "#6495ED",
          "#00BFFF",
          "#1E90FF",
          "#4169E1",
          "#0000FF",
          "#00008B",
          "#808080",
          "#778899",
          "#2F4F4F",
          "#000000",
        ],
        IsOpenNewThought: false,
        isUpdateLooseThought: false,
      };
    },
    watch: {
      looseThoughts() {
        this.editStyleOnResizeWindow();
      },
    },
    beforeMount() {
      window.addEventListener("resize", this.editStyleOnResizeWindow);
      this.$store.commit("thoughtsStore/setLooseThought", []);
      this.$store.dispatch("thoughtsStore/getLooseThoughts");
    },
    mounted() {
      this.randomColor();
    },
    updated() {
      this.randomColor();
    },
    unmounted() {
      this.$store.commit("thoughtsStore/setLooseThought", []);
      window.removeEventListener("resize", this.editStyleOnResizeWindow);
    },
    computed: {
      ...mapGetters({
        looseThoughts: "thoughtsStore/looseThoughts",
        stateOverlay: "thoughtsStore/stateOverlayForLT",
      }),
    },
    components: {
      NewLooseThoughts,
    },
    methods: {
      redirectHome() {
        this.$router.push(`/`);
      },
      commitLooseThoughts(item) {
        this.isUpdateLooseThought = true;
        setItemToLS("updateThought", item);
        this.IsOpenNewThought = true;
        setTimeout(() => {
          this.isUpdateLooseThought = false;
        }, 1000);
      },
      randomColor() {
        let metatags = document.getElementsByClassName(
          "list-item__metatags-list-item"
        );
        let i = 0;
        for (let el of metatags) {
          i++;
          el.style.backgroundColor = `${
            this.colorArray[i % this.colorArray.length]
          }`;

          let rgb = hexToRGB(`${this.colorArray[i % this.colorArray.length]}`);
          if (
            1 - (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255 <
            0.25
          )
            el.style.color = `black`;
        }
      },
      openInNewCanvas(item) {
        setItemToLS("newCanvas", item);
        this.$store.commit("canvasStore/setNewCanvas", item);

        this.$store.commit(
          "thoughtsStore/setCurrentThoughtId",
          item.knowledgeObjectId
        );
        this.$router.push(`/blank-canvas`);
      },
      toggleNewThought() {
        this.IsOpenNewThought = !this.IsOpenNewThought;
      },
      deleteLooseThought(id) {
        this.$store.dispatch("thoughtsStore/deleteLooseThought", id);
      },
      editStyleOnResizeWindow() {
        let looseThoughtBody = document.getElementById("loose-thought-body");
        let padding = window.innerWidth >= 1500 ? 59 : 43;
        looseThoughtBody.style.height = `calc(100vh - ${padding}px)`;
        looseThoughtBody.style.top = `${padding}px`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loose-thoughts {
    height: 100vh;

    &__title {
      color: black;
      font-size: 30px;
      padding-top: 40px;
    }

    &__top-block {
      border-bottom: 1px solid rgb(146, 146, 146);
      background: rgb(221, 221, 221);
      padding: 1px;
      display: flex;
      justify-content: right;
      align-items: center;
      position: absolute;
      width: 100vw;
      z-index: 99;
      height: 43px;

      .top_block__home-btn {
        border: none;
        background: #6c757d;
        color: white;
        border-radius: 3px;
        padding: 0 10px;
        margin-right: 20px;
        height: 30px;

        &:hover {
          background: #575e64;
        }
      }
    }

    &__img-top {
      display: block;
      width: 280px;
      z-index: 26;
      position: absolute;
      top: 43px;

      &:hover {
        cursor: pointer;
      }
    }

    &__list {
      display: flex;
      flex-wrap: nowrap;
      padding: 50px 50px 1px 50px;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      &-item {
        position: relative;
        width: 250px;
        border: 1px solid rgb(66, 66, 66);
        box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.24),
          0 6px 10px 3px rgba(0, 0, 0, 0.19);
        margin-bottom: 20px;
        height: fit-content;

        &-body {
          width: 248px;
          word-wrap: break-word;
          padding: 7px;
          overflow-y: auto;
          border-bottom: 1px solid rgb(44, 44, 44);
          height: 300px;
        }

        .list-item__metatags-list {
          display: flex;
          align-content: flex-start;
          flex-wrap: wrap;
          padding: 10px;
          height: 78px;
          overflow-y: auto;
          overflow-x: hidden;

          &-item {
            font-size: 10px;
            padding: 1px 4px;
            border-radius: 7px;
            margin-right: 5px;
            margin-bottom: 5px;
            color: white;
            width: 69px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            height: 18px;
          }
        }

        .list-item__btn {
          overflow: hidden;
          border: none;
          background: unset;
          width: 23px;
          height: 23px;
          position: absolute;

          &-add {
            right: -9px;
            top: -8px;
            border: 1px;

            &:hover {
              cursor: pointer;
              box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
                0 17px 50px 0 rgba(0, 0, 0, 0.19);
              border-radius: 50%;
            }

            &::after {
              content: " ";
              width: 25px;
              height: 25px;
              background-image: url(../assets/icon-open.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              position: absolute;
              right: -1px;
              top: -0.5px;
            }
          }

          &-save {
            right: -9px;
            bottom: -8px;
            border-radius: 50%;
            border: 1px solid rgb(151, 151, 151);
            background-color: rgb(233, 233, 233);

            &:hover {
              cursor: pointer;
              box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
                0 17px 50px 0 rgba(0, 0, 0, 0.19);
            }

            &::after {
              content: " ";
              width: 16px;
              height: 16px;
              background-image: url(../assets/icon-brain.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              position: absolute;
              right: 3px;
              top: 2px;
            }
          }

          &-delete {
            position: absolute;
            overflow: hidden;
            bottom: -10px;
            right: 20px;
            width: 25px;
            height: 25px;
            padding: 0;
            border-radius: 50%;
            border: 1px solid rgb(151, 151, 151);
            color: rgb(100, 100, 100);

            &:hover {
              cursor: pointer;
              box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
                0 17px 50px 0 rgba(0, 0, 0, 0.19);
            }
          }
        }

        &:not(:last-child) {
          margin-right: 50px;
        }
      }
    }

    &__img-bottom {
      position: absolute;
      width: 280px;
      bottom: 0.5px;
      right: 0;
    }

    .invalid-input {
      border-color: rgb(197, 0, 0) !important;
      background: rgba(155, 65, 65, 0.459) !important;
    }
  }

  #testNewLooseThought {
    position: absolute;
    top: 63px;
    animation-duration: 300ms;
    animation-name: slidein;
    animation-iteration-count: 1;
  }

  @media (max-width: 500px) {
    #testNewLooseThought {
      top: 60px;
    }

    .loose-thoughts {
      &__top-block {
        height: 43px;
        z-index: 99;
      }

      &__img-top {
        top: 43px;
      }

      &__img-bottom {
        width: 320px;
      }
    }
  }

  @media (min-width: 1500px) {
    #testNewLooseThought {
      top: 80px;
    }

    .loose-thoughts {
      &__top-block {
        height: 59px;
        z-index: 99;
      }

      &__img-top {
        top: 59px;
      }

      &__img-bottom {
        width: 320px;
      }
    }
  }

  @media (min-width: 1800px) {
    #testNewLooseThought {
      top: 80px;
    }

    .loose-thoughts {
      &__top-block {
        height: 59px;
      }

      &__img-top {
        top: 59px;
      }

      &__img-bottom {
        width: 350px;
      }
    }
  }
</style>
