import { filterKOPosition, getItemFromLS, setItemToLS } from "../functions";
import { store } from "./index";
import axios from "../axios";

export const state = () => ({
  KOItems: [],
  createdKOId: "",
  stateOverlayForKO: false,
});
export const getters = {
  KOItems: (s) => s.KOItems,
  createdKOId: (s) => s.createdKOId,
  stateOverlayForKO: (s) => s.stateOverlayForKO,
};
export const mutations = {
  setKOItems: (state, KOItems) => {
    state.KOItems = KOItems;
  },
  setCreatedKOId(state, createdKOId) {
    state.createdKOId = createdKOId;
  },
  setStateOverlayForKO(state, payload) {
    state.stateOverlayForKO = payload;
  },
};
export const actions = {
  async saveNewKOItem({ commit, dispatch }, payload) {
    const newItemKO = getItemFromLS("newKO");
    let paramNewKO = {
      body: newItemKO.body,
      metatags: newItemKO.metatags ? newItemKO.metatags : [],
      type: "standard KO",
    };

    const requestBody = JSON.stringify(paramNewKO);
    commit("setStateOverlayForKO", true);
    await axios
      .post("main/knowledge_object", requestBody)
      .then((res) => {
        newItemKO.knowledgeObjectId = res.data.body.knowledgeObjectId;
        if (payload.type === "previousCanvas") {
          dispatch("saveNewKOItemByPreviousCanvas", newItemKO);
        } else {
          dispatch("saveNewKOItemByBlankCanvas", newItemKO);
        }
        commit("setStateOverlayForKO", false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  },

  async updateKOItem({ commit, getters, dispatch }, payload) {
    commit("setStateOverlayForKO", true);
    const updatedKO = getItemFromLS("updatedKO");
    let updateKO = {
      body: updatedKO.body,
      metatags: updatedKO.metatags ? updatedKO.metatags : [],
    };

    const requestBody = JSON.stringify(updateKO);

    await axios.put(`main/knowledge_object/${payload.id}`, requestBody);

    if (payload.type === "blankCanvas") {
      dispatch("updateBlankCanvasKOInfo", { payload, updatedKO });
    } else {
      dispatch("updatePreviousCanvasKOInfo", { payload, updatedKO });
    }

    commit("setStateOverlayForKO", false);
  },

  async deleteKOItem({ commit, dispatch }, payload) {
    commit("setStateOverlayForKO", true);

    try {
      await axios.delete(`main/knowledge_object/${payload}`);
      commit("setStateOverlayForKO", false);
      let KOItems = getItemFromLS("KOItems");
      let koLocations = getItemFromLS("koLocations");
      if (KOItems) {
        dispatch("deleteKOFromListAndLocations", {
          KOItems,
          koLocations,
          KOId: payload,
        });
      }
    } catch (error) {
      console.error("Error deleting KO item:", error);
      commit("setStateOverlayForKO", false);
    } finally {
      commit("setStateOverlayForKO", false);
    }
  },

  deleteKOItems({ commit, dispatch }, KOItems) {
    for (const KOItem of KOItems)
      dispatch("deleteKOItem", KOItem.knowledgeObjectId);
  },

  saveNewKOItemByPreviousCanvas({ commit }, newItemKO) {
    const KOItems = store.getters["canvasStore/previousCanvas"].KOs;
    KOItems.push(newItemKO);
    commit("setCreatedKOId", newItemKO.knowledgeObjectId);
    setItemToLS("newKO", newItemKO);
    setItemToLS("KOItems", KOItems);
    filterKOPosition();
    let koLocations = getItemFromLS("koLocations");
    koLocations.push({
      id: newItemKO.knowledgeObjectId,
      x: newItemKO.left,
      y: newItemKO.top,
    });
    setItemToLS("koLocations", koLocations);
    commit("setKOItems", KOItems);
  },

  saveNewKOItemByBlankCanvas({ commit, getters }, newItemKO) {
    const KOItems = getters.KOItems;
    KOItems.push(newItemKO);
    setItemToLS("newKO", newItemKO);
    setItemToLS("KOItems", KOItems);
    filterKOPosition();
    let koLocations = getItemFromLS("koLocations");
    koLocations.push({
      id: newItemKO.knowledgeObjectId,
      x: newItemKO.left,
      y: newItemKO.top,
    });
    setItemToLS("koLocations", koLocations);
    commit("setKOItems", KOItems);
  },

  deleteKOFromListAndLocations({ commit }, payload) {
    const koLocations = payload.koLocations.filter(
      (KOItem) => KOItem.id !== payload.KOId
    );
    const KOItems = payload.KOItems.filter(
      (KOItem) => KOItem.knowledgeObjectId !== payload.KOId
    );
    setItemToLS("KOItems", KOItems);
    setItemToLS("koLocations", koLocations);
    commit("setKOItems", KOItems);
  },

  updateBlankCanvasKOInfo({ commit, getters }, { payload, updatedKO }) {
    const KOItemsList = getters.KOItems;
    KOItemsList.forEach((el) => {
      if (el.knowledgeObjectId === payload.id) {
        el.body = updatedKO.body;
        el.metatags = updatedKO.metatags;
      }
    });
    setItemToLS("KOItems", KOItemsList);
    commit("setKOItems", KOItemsList);
    filterKOPosition();
    let koLocations = getItemFromLS("koLocations");
    if (!koLocations.find((el) => el.id === payload.id)) {
      koLocations.push({ id: payload.id, x: updatedKO.left, y: updatedKO.top });
      setItemToLS("koLocations", koLocations);
      setItemToLS("newCanvas", {});
    }
  },

  updatePreviousCanvasKOInfo({ commit }, { payload, updatedKO }) {
    const previousCanvas = store.getters["canvasStore/previousCanvas"];
    if (previousCanvas.KOs)
      previousCanvas.KOs.forEach((el) => {
        if (el.knowledgeObjectId === payload.id) {
          el.body = updatedKO.body;
          el.metatags = updatedKO.metatags;
        }
      });
    store.commit("canvasStore/setPreviousCanvas", previousCanvas);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
