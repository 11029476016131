<template>
  <div class="new_loose-thoughts">
    <div class="new_loose-thoughts__new-item">
      <div class="new-item__top-color"></div>
      <textarea
        v-model="textAreaValue"
        name="textarea"
        class="new-item__body"
        col="30"
        rows="5"
      ></textarea>
      <div class="new-item__meta-block">
        <ul class="meta-block__list" v-if="metatags.length">
          <li
            class="meta-block__list-item"
            v-for="metatag in metatags"
            :key="metatag.index"
            @click="updateMetatag(metatag)"
          >
            {{ metatag }}
          </li>
        </ul>
        <div class="meta-block__hash-icon">#</div>
        <input
          type="text"
          id="meta-block__input"
          v-model="hashInputValue"
          v-on:input="validationMetatags"
          v-on:change="saveHashInfo"
          v-on:blur="saveHashInfo"
          v-on:keydown="saveHashtagOnKeypress"
        />
      </div>
      <ul class="new-item__list-btn">
        <li v-if="!isUpdate" class="new-item__list-btn-item">
          <button class="list-item-btn" v-on:click="minimise">Minimise</button>
        </li>
        <li v-if="!isUpdate" class="new-item__list-btn-item">
          <button class="list-item-btn" v-on:click="shelve">Shelve</button>
        </li>
        <li v-if="!isUpdate" class="new-item__list-btn-item">
          <button class="list-item-btn" v-on:click="explode">Explode</button>
        </li>
        <li v-if="isUpdate" class="new-item__list-btn-item">
          <button class="list-item-btn" v-on:click="updateLooseThought">
            Update
          </button>
        </li>
        <li class="new-item__list-btn-item">
          <b-button class="list-item-btn" @click="clearNewLooseThought">
            <i class="bi bi-bootstrap-reboot" style="color: black"></i>
          </b-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {
    setItemToLS,
    getItemFromLS,
    getValidateHashValue,
    validateHashTag,
  } from "../functions";
  export default {
    name: "NewLooseThought",
    data() {
      return {
        isOpenAddingBlock: false,
        isActiveInput: false,
        hashInputValue: "",
        textAreaValue: "",
        metatags: [],
        isUpdate: false,
      };
    },
    props: {
      toggleNewThought: Function,
      isUpdateLooseThought: Boolean,
    },
    beforeMount() {
      this.isUpdate = this.isUpdateLooseThought;

      let looseThought = this.isUpdate
        ? getItemFromLS("updateThought")
        : getItemFromLS("newThought");

      if (looseThought && (looseThought.body || looseThought.metatags)) {
        this.textAreaValue = looseThought.body;
        this.metatags = looseThought.metatags;
      }
    },
    methods: {
      updateLooseThought() {
        let newLooseThought = {
          body: this.textAreaValue,
          metatags: this.metatags,
        };

        let payload = {
          id: getItemFromLS("updateThought").knowledgeObjectId,
          type: "newLooseThought",
        };

        if (newLooseThought.metatags || newLooseThought.body) {
          setItemToLS("updatedKO", newLooseThought);
          this.$store.dispatch("KOStore/updateKOItem", payload).then(() => {
            this.clearNewLooseThought();
            setItemToLS("updateThought", {});
            this.$store.dispatch("thoughtsStore/getLooseThoughts");
          });
          this.toggleNewThought();
        }
      },
      validationMetatags(event) {
        let hashInput = document.getElementById("meta-block__input");
        validateHashTag(event, hashInput);
      },
      saveHashInfo() {
        let hashInput = document.getElementById("meta-block__input");
        if (!hashInput.classList.contains("invalid-input")) {
          this.hashInputValue = "#" + this.hashInputValue;
          if (this.hashInputValue.includes(" ")) {
            let updatedString = getValidateHashValue(this.hashInputValue);
            if (!this.metatags.includes(updatedString))
              this.metatags.push(updatedString);
          } else {
            if (this.hashInputValue !== "#")
              if (!this.metatags.includes(this.hashInputValue))
                this.metatags.push(this.hashInputValue);
          }
        } else {
          return;
        }
        this.hashInputValue = "";
      },
      updateMetatag(metatag) {
        this.saveHashInfo();
        this.hashInputValue = metatag.substring(1);
        const hashIndex = this.metatags.indexOf(metatag);
        this.metatags.splice(hashIndex, 1);
      },
      minimise() {
        const newThought = {
          body: this.textAreaValue,
          metatags: this.metatags,
          type: "loose thought",
        };
        if (newThought.body || newThought.metatags.length) {
          setItemToLS("newThought", newThought);
        }
        this.toggleNewThought();
      },
      shelve() {
        const newThought = {
          body: this.textAreaValue,
          metatags: this.metatags,
          type: "loose thought",
        };
        if (newThought.body || newThought.metatags.length) {
          setItemToLS("newThought", newThought);
          this.$store.dispatch("thoughtsStore/saveNewThought");
        }
        this.clearNewLooseThought();
        this.toggleNewThought();
      },
      explode() {
        const item = {
          body: this.textAreaValue,
          metatags: this.metatags,
        };
        setItemToLS("newCanvas", item);
        this.$store.commit("canvasStore/setNewCanvas", item);
        if (this.$route.path !== "/blank-canvas")
          this.$router.push(`/blank-canvas`);
        else {
          this.toggleNewThought();
        }
        this.clearNewLooseThought();
      },
      clearNewLooseThought() {
        if (this.isUpdate)
          setItemToLS("updateThought", {
            knowledgeObjectId: getItemFromLS("updateThought").knowledgeObjectId,
          });
        else setItemToLS("newThought", {});
        this.hashInputValue = "";
        this.textAreaValue = "";
        this.metatags = [];
      },
      saveHashtagOnKeypress(e) {
        if (`${e.key}` === "Enter") this.saveHashInfo();
      },
    },
  };
</script>

<style lang="scss">
  .new_loose-thoughts {
    margin: 0;
    z-index: 30;
    position: relative;

    &__new-item {
      position: relative;
      width: 220px;
      height: 360px;
      margin-left: 30px;
      border: 1px solid rgb(100, 100, 100);
      background: rgb(226, 226, 226);

      .new-item {
        &__top-color {
          height: 20px;
          background: rgb(93, 222, 245);
          border-bottom: 1px solid rgb(100, 100, 100);
        }

        &__body {
          padding: 5px 10px;
          height: 200px;
          margin-bottom: 105px;
          overflow-y: auto;
          overflow-x: hidden;
          word-break: break-word;
          text-align: left;

          background-color: transparent;
          width: 95%;
          border: none;
          outline: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          resize: none;
        }

        &__meta-block {
          position: absolute;
          bottom: 35px;
          width: 100%;
          z-index: 5;

          .meta-block__list {
            margin-right: auto;
            margin-left: auto;
            width: 85%;
            border: 1px solid rgb(49, 49, 49);
            border-bottom: none;
            background: rgb(243, 243, 243);
            max-height: 75px;
            overflow-x: hidden;
            overflow-y: auto;
            word-break: break-word;

            &__list-item {
              text-align: left;
              padding: 0 10px;
            }
          }

          .meta-block__hash-icon {
            position: absolute;
            bottom: 2px;
            left: 17.5px;
          }

          #meta-block__input {
            width: 85%;
            background: rgb(226, 226, 226);
            border: 1px solid rgb(100, 100, 100);
            padding-left: 10px;
            outline: none;
          }
        }

        &__list-btn {
          display: flex;
          justify-content: center;
          height: 30px;
          flex-wrap: nowrap;
          align-items: flex-start;

          &-item {
            border-radius: 5px;
            background: rgb(93, 222, 245);
            box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.24),
              0 2px 3px 1px rgba(0, 0, 0, 0.19);
            margin: 0 3px;
            height: 17px;

            .list-item-btn {
              display: block;
              background: transparent;
              border: 0;
              width: 100%;
              font-size: 12px;
              padding: 0 3px;
            }

            :hover {
              box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.24),
                0 4px 6px 3px rgba(0, 0, 0, 0.19);
            }

            :not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  #darken-block {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 99.99vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 25;
    //filter: blur(5px);
  }

  .invalid-input {
    border-color: rgb(197, 0, 0) !important;
    background: rgba(155, 65, 65, 0.459) !important;
  }
</style>
