<template>
  <div id="main-adding-block">
    <button
      type="button"
      class="main-adding-block__add-hash main-adding-block__btn"
      v-on:click="toggleHashBox"
    ></button>
    <textarea
      v-model="textAreaValue"
      name="textarea"
      id="textarea-main"
      col="30"
      rows="5"
      @input="showLoader"
    ></textarea>
    <div v-if="isLoading" class="loader"></div>
    <button
      type="button"
      class="main-adding-block__save main-adding-block__btn"
      v-on:click="commitKO(textAreaValue, metatags)"
    ></button>

    <div v-if="isOpenHashBox" class="hash-box">
      <div id="hash-box-icon">#</div>
      <input
        v-model="hashInputValue"
        type="text"
        id="hash-box-input"
        v-on:input="validationMetatags"
        v-on:change="saveHashInfo"
        v-on:blur="saveHashInfo"
        v-on:keydown="saveHashtagOnKeypress"
      />
      <ul class="hash-list">
        <li
          class="hash-list-item"
          v-for="hash in metatags"
          :key="hash.index"
          @click="updateMetatag(hash)"
        >
          {{ hash }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {
    convertToPercentage,
    convertToPixeles,
    getItemFromLS,
    getValidateHashValue,
    setItemToLS,
    validateHashTag,
  } from "../functions";
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        isOpenHashBox: false,
        textAreaValue: "",
        hashInputValue: "",
        metatags: [],
        knowledgeObjectIdForUpdate: "",
        positionTextEntryBox: {},
        canvasInterval: null,
        isLoading: false,
      };
    },
    props: {
      isCommitKO: Boolean,
      KOForUpdate: Object,
      type: String,
      previousCanvasId: String,
      addingBlockPosition: Object,
    },
    beforeMount() {
      let KOForNewCanvas = getItemFromLS("newCanvas");
      if (this.KOForUpdate.knowledgeObjectId) {
        this.metatags = this.KOForUpdate.metatags;
        this.textAreaValue = this.KOForUpdate.body;
        this.knowledgeObjectIdForUpdate = this.KOForUpdate.knowledgeObjectId;
      } else {
        if (KOForNewCanvas) {
          this.textAreaValue = KOForNewCanvas.body;
          this.metatags = KOForNewCanvas.metatags;
        }
      }
    },
    mounted() {
      const newCanvas = this.getItemFromLS("newCanvas");
      this.setPositionEntryBox();
      if (newCanvas && (newCanvas.body || newCanvas.metatags)) {
        this.textAreaValue
          ? (this.textAreaValue = newCanvas.body)
          : (this.textAreaValue = "");
        this.metatags
          ? (this.metatags = newCanvas.metatags)
          : (this.metatags = []);
        this.isOpenHashBox = true;
      }
      this.canvasInterval = setInterval(() => {
        if (
          (this.isCommitKO && this.textAreaValue) ||
          (this.isCommitKO && this.metatags.length)
        )
          this.commitKO(this.textAreaValue, this.metatags);
      }, 30000);
      this.$nextTick(() => {
        const textAreaMain = document.getElementById("textarea-main");
        if (textAreaMain) {
          textAreaMain.focus();
        }
      });
    },
    beforeUnmount() {
      if (
        (this.textAreaValue || (this.metatags && this.metatags.length)) &&
        this.hashInputValue !== "#"
      ) {
        // this.commitKO(this.textAreaValue, this.metatags);
      }
      clearInterval(this.canvasInterval);
    },
    computed: {
      ...mapGetters({
        storeNewCanvas: "canvasStore/newCanvas",
      }),
    },
    watch: {
      isCommitKO() {
        if (
          (this.isCommitKO && this.textAreaValue) ||
          (this.isCommitKO && this.metatags.length)
        )
          this.commitKO(this.textAreaValue, this.metatags);
      },
      addingBlockPosition: {
        handler(newVal, oldVal) {
          this.$nextTick(() => {
            this.setPositionEntryBox();
            const textAreaMain = document.getElementById("textarea-main");

            if (textAreaMain && !this.isOpenHashBox) {
              textAreaMain.focus();
            }
          });
        },
        deep: true,
        immediate: true,
      },
      KOForUpdate() {
        if (Object.keys(this.KOForUpdate).includes("knowledgeObjectId")) {
          this.textAreaValue = this.KOForUpdate.body;
          (this.metatags = this.KOForUpdate.metatags),
            (this.knowledgeObjectIdForUpdate =
              this.KOForUpdate.knowledgeObjectId);
          this.isOpenHashBox = true;
        }
      },
    },
    methods: {
      getItemFromLS,
      convertToPixeles,
      getValidateHashValue,
      convertToPercentage,
      toggleHashBox() {
        this.isOpenHashBox = !this.isOpenHashBox;
        this.$nextTick(() => {
          if (this.isOpenHashBox) {
            const hashInput = document.getElementById("hash-box-input");
            if (hashInput) {
              hashInput.focus();
            }
          }
        });
      },

      validationMetatags(event) {
        let hashInput = document.getElementById("hash-box-input");
        validateHashTag(event, hashInput);
      },
      saveHashInfo() {
        let hashInput = document.getElementById("hash-box-input");
        if (!hashInput.classList.contains("invalid-input")) {
          this.hashInputValue = "#" + this.hashInputValue;
          if (this.hashInputValue.includes(" ")) {
            let updatedString = getValidateHashValue(this.hashInputValue);
            if (!this.metatags.includes(updatedString))
              this.metatags.push(updatedString);
          } else {
            if (!this.metatags) this.metatags = [];
            if (this.hashInputValue !== "#")
              if (!this.metatags.includes(this.hashInputValue))
                this.metatags.push(this.hashInputValue);
          }
        }
        this.hashInputValue = "";
      },
      setPositionEntryBox() {
        const addingBlock = document.getElementById("main-adding-block");
        const pageWidth = document.body.clientWidth;
        const pageHeight = document.body.clientHeight;

        if (this.addingBlockPosition.top && this.addingBlockPosition.left) {
          if (
            this.addingBlockPosition.top <
            pageHeight - (addingBlock.clientWidth + 100)
          ) {
            this.positionTextEntryBox.top = this.addingBlockPosition.top;
          } else {
            this.positionTextEntryBox.top =
              pageHeight - (addingBlock.clientWidth + 100);
          }

          if (
            this.addingBlockPosition.left <
            pageWidth - (addingBlock.clientWidth + 350)
          ) {
            this.positionTextEntryBox.left = this.addingBlockPosition.left;
          } else {
            this.positionTextEntryBox.left =
              pageWidth - (addingBlock.clientWidth + 350);
          }

          if (pageWidth < 576) {
            if (
              this.addingBlockPosition.left <
              pageWidth - (addingBlock.clientWidth + 200)
            ) {
              this.positionTextEntryBox.left = this.addingBlockPosition.left;
            } else {
              this.positionTextEntryBox.left =
                pageWidth - (addingBlock.clientWidth + 200);
            }
          }

          addingBlock.style.top = `${this.positionTextEntryBox.top}px`;
          addingBlock.style.left = `${this.positionTextEntryBox.left}px`;
          
        }else{
          this.positionTextEntryBox.top = 100;
          this.positionTextEntryBox.left = 100;
          addingBlock.style.top = `${this.positionTextEntryBox.top}px`;
          addingBlock.style.left = `${this.positionTextEntryBox.left}px`;
        }
      },
      commitKO(textAreaValue, metatags) {
        if (!textAreaValue && !metatags) {
          return;
        }

        let offsetPositionY = 9;
        let offsetPositionX = 1;

        if (this.$route.name === "PreviousCanvas") {
          offsetPositionY += 10.5;
        }

        const newKO = {
          body: textAreaValue,
          metatags,
          top:
            convertToPercentage(0, this.positionTextEntryBox.top + 60).y -
            offsetPositionY,
          left:
            convertToPercentage(this.positionTextEntryBox.left, 0).x -
            offsetPositionX,
        };

        if (this.knowledgeObjectIdForUpdate) {
          setItemToLS("updatedKO", newKO);
          let payload = {
            id: this.knowledgeObjectIdForUpdate,
            type: this.type,
            previousCanvasId: this.previousCanvasId,
          };
          this.$store.dispatch("KOStore/updateKOItem", payload);
        } else {
          if (newKO.body || newKO.metatags.length) {
            setItemToLS("newKO", newKO);
            this.$store.dispatch("KOStore/saveNewKOItem", { type: this.type });
          }
        }
        this.textAreaValue = "";
        this.hashInputValue = "";
        this.metatags = [];
        this.knowledgeObjectIdForUpdate = "";

        if (this.storeNewCanvas !== null) {
          this.$store.commit("canvasStore/setNewCanvas", {});
          setItemToLS("newCanvas", {});
        }
      },
      updateMetatag(hash) {
        if (this.knowledgeObjectIdForUpdate) {
          this.saveHashInfo();
          this.hashInputValue = hash.substring(1);
          const hashIndex = this.metatags.indexOf(hash);
          this.metatags.splice(hashIndex, 1);
        }
      },
      saveHashtagOnKeypress(e) {
        if (`${e.key}` === "Enter") this.saveHashInfo();
      },
      showLoader() {
        if (!this.isLoading) {
          this.isLoading = true;
        }

        clearTimeout(this.loadingTimeout);

        this.loadingTimeout = setTimeout(() => {
          this.isLoading = false;
        }, 500);
      },
    },
  };
</script>

<style scoped lang="scss">
  #main-adding-block {
    position: absolute;
    width: 20vw;
    height: 20vw;
    max-width: 350px;
    max-height: 350px;
    background-color: white;
    background-image: linear-gradient(
        rgb(153, 153, 153) 20%,
        transparent 20%,
        transparent 80%,
        rgb(153, 153, 153) 80%
      ),
      linear-gradient(
        90deg,
        rgb(153, 153, 153) 20%,
        transparent 20%,
        transparent 80%,
        rgb(153, 153, 153) 80%
      ),
      linear-gradient(
        rgb(153, 153, 153) 20%,
        transparent 20%,
        transparent 80%,
        rgb(153, 153, 153) 80%
      ),
      linear-gradient(
        90deg,
        rgb(153, 153, 153) 20%,
        transparent 20%,
        transparent 80%,
        rgb(153, 153, 153) 80%
      );
    background-size: 1px 20vw, 20vw 1px, 1px 20vw, 20vw 1px;
    background-position: 0 0, 0 0, 100% 100%, 100% 100%;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    z-index: 10;

    #textarea-main {
      margin: 2px;
      margin-top: 12px;
      width: 95%;
      height: 90%;
      border: none;
      overflow: auto;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none;
      font-size: 24px;
    }

    .main-adding-block {
      &__btn {
        overflow: hidden;
        border: none;
        background: unset;
        width: 23px;
        height: 23px;
        position: absolute;
        z-index: 20;
      }

      &__add-hash {
        right: -9px;
        top: -8px;
        border-radius: 50%;

        &:hover {
          cursor: pointer;
          box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
            0 17px 50px 0 rgba(0, 0, 0, 0.19);
        }

        &::after {
          content: " ";
          width: 25px;
          height: 25px;
          background-image: url(../assets/icon-plus.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          right: -1px;
          top: -0.5px;
        }
      }

      &__save {
        right: -9px;
        bottom: -8px;
        border-radius: 50%;
        border: 1px solid rgb(151, 151, 151);
        background-color: rgb(233, 233, 233);
        &:hover {
          cursor: pointer;
          box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
            0 17px 50px 0 rgba(0, 0, 0, 0.19);
        }

        &::after {
          content: " ";
          width: 16px;
          height: 16px;
          background-image: url(../assets/icon-brain.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          right: 3px;
          top: 2px;
        }
      }
    }

    .hash-box {
      width: 270px;
      height: 160px;
      position: absolute;
      top: 0;
      left: 103%;
      border: 2px solid black;
      background: rgb(255, 253, 253);

      #hash-box-icon {
        position: absolute;
        top: 3px;
        left: 3px;
      }

      #hash-box-input {
        width: 100%;
        height: 30px;
        border: none;
        border-bottom: 2px solid black;
        overflow: auto;
        outline: none;
        background: rgb(214, 213, 213);
        padding-left: 13px;
      }

      .hash-list {
        max-height: 126px;
        overflow-y: auto;

        &-item {
          padding-left: 3px;
          text-align: start;
          word-break: break-word;
        }

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background-color: #4e695f;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(12, 24, 94, 0.514);
        }
      }
    }
  }

  .loader {
    position: absolute;
    top: 85%;
    left: 85%;
    border: 5px solid #f3f3f3;
    border-top: 5px solid green;
    border-left: 5px solid green;
    border-bottom: 5px solid green;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .invalid-input {
    border-color: rgb(197, 0, 0) !important;
    background: rgba(155, 65, 65, 0.459) !important;
  }

  @media (max-width: 992px) {
    .loader {
      top: 80%;
      left: 80%;
      border: 4px solid #f3f3f3;
      border-top: 4px solid green;
      border-left: 4px solid green;
      border-bottom: 4px solid green;
      border-radius: 50%;
      width: 1.3em;
      height: 1.3em;
    }

    #main-adding-block {
      width: 30vw;
      height: 30vw;
      #textarea-main {
        font-size: 1em;
      }
    }
  }

  @media (max-width: 768px) {
    .loader {
      top: 70%;
      left: 70%;
      border: 3px solid #f3f3f3;
      border-top: 3px solid green;
      border-left: 3px solid green;
      border-bottom: 3px solid green;
      border-radius: 50%;
      width: 1.1em;
      height: 1.1em;
    }

    #main-adding-block {
      #textarea-main {
        font-size: 1em;
      }
    }
  }

  @media (max-width: 576px) {
    .loader {
      top: 80%;
      left: 80%;
      border: 2px solid #f3f3f3;
      border-top: 2px solid green;
      border-left: 2px solid green;
      border-bottom: 2px solid green;
      border-radius: 50%;
      width: 1em;
      height: 1em;
    }
    #main-adding-block {
      width: 150px;
      height: 150px;

      .hash-box {
        width: 150px;
        height: 150px;

        .hash-list {
          max-height: 115px;
        }
      }
    }
  }
</style>
