<template>
  <div id="app">
    <div
      class="audun_success"
      :style="`opacity: ${isSaveLT ? '1' : '0'}; visibility:${
        isSaveLT ? 'unset' : 'hidden'
      };`"
    >
      <p>Save success!</p>
    </div>
    <router-view />
  </div>
</template>

<script>
  import { getItemFromLS } from "./functions";
  import { mapGetters } from "vuex";

  export default {
    name: "App",
    data() {
      return {
        curURL: "",
      };
    },
    mounted() {
      this.curURL = window.location.href;
    },
    computed: {
      ...mapGetters({
        isSaveLT: "thoughtsStore/isSaveThoughtSuccess",
      }),
    },
    watch: {
      async curURL() {
        if (this.curURL.indexOf("code=") != -1) {
          const code = this.curURL.split("=")[1];
          if (!this.getItemFromLS("access_token")) {
            await this.$store.dispatch("authStore/getToken", code);
          }
        }
      },
    },
    methods: {
      getItemFromLS,
    },
  };
</script>

<style lang="scss">
  @import "bootstrap/dist/css/bootstrap.css";
  @import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

  #app {
    font-family: "Roboto", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    min-height: 100vh;
    box-sizing: border-box;

    .audun_success {
      color: #ffffff;
      background-color: #7ddf7c;
      font-family: "Source Sans Pro", sans-serif;
      border-radius: 0.5em;
      border: 1px solid;
      padding: 12px;
      width: 180px;
      transition: opacity 0.2s;
      position: absolute;
      top: 60px;
      left: calc(50vw - 90px);
      display: flex;
      justify-content: space-around;
      align-content: center;
      align-items: center;
      z-index: 100;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #9a9797;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(74, 81, 121, 0.51);
  }
</style>
