<template>
  <div class="KO-list">
    <p class="KO-list__item-body">{{ this.body }}</p>
    <hr class="separation-strip" />
    <ul class="KO-list__metatags">
      <li
        class="KO-list__metatags-item"
        v-for="hash in this.metatags"
        :key="hash.index"
      >
        {{ hash }}
      </li>
    </ul>
    <!-- <hr class="separation-strip" />
    <i
      class="bi bi-card-text"
      style="font-size: 2rem; color: #343a40; cursor: pointer"
      @click="modalShow = true"
    ></i> -->
    <div class="KO-list__btn KO-list__btn-update">
      <i
        class="bi bi-pencil-square"
        style="font-size: 1rem; color: #343a40; cursor: pointer"
        @click="updateKo(item)"
      ></i>
    </div>
    <button
      type="button"
      class="KO-list__btn KO-list__btn-delete"
      @click="deleteKO(item.knowledgeObjectId)"
    />

    <!-- <b-modal
      v-model="modalShow"
      modal-footer="false"
      centered
      scrollable
      hide-footer
      hide-header
    >
      <KODetails :body="this.body" :metatags="this.metatags" />
    </b-modal> -->

    <!-- <BModal
      id="modal-center"
      title="Details"
      hide-footer
      v-model="modalShow"
      centered
    >
      <KODetails :body="this.body" :metatags="this.metatags" />
    </BModal> -->
  </div>
</template>

<script>
  import KODetails from "./KODetails";

  export default {
    name: "KOItem",
    props: {
      body: String,
      metatags: Array,
      knowledgeObjectId: String,
      type: String,
      //FUNCTIONS
      deleteKO: Function,
      updateKo: Function,
    },
    data() {
      return {
        item: {
          body: this.body,
          metatags: this.metatags,
          knowledgeObjectId: this.knowledgeObjectId,
        },
        modalShow: false,
      };
    },
    components: {
      KODetails,
    },
    beforeUpdate() {
      this.item = {
        body: this.body,
        metatags: this.metatags,
        knowledgeObjectId: this.knowledgeObjectId,
      };
    },
  };
</script>

<style scoped lang="scss">
  .KO-list {
    position: absolute;
    width: 220px;
    border: 1px solid rgb(66, 66, 66);
    padding: 10px 10px 25px 10px;
    color: rgb(0, 0, 0);
    background: radial-gradient(
      circle farthest-corner at 100px 50px,
      #d3d0d4,
      #b7b2bb
    );
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24),
      0 2px 3px 1px rgba(0, 0, 0, 0.19);
    border-radius: 5px;

    .separation-strip {
      margin: 3px 0;
      padding: 0;
    }

    &__item-body {
      width: 100%;
      height: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      -ms-line-clamp: 7;
      -webkit-line-clamp: 7;
      line-clamp: 7;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      text-align: left;
    }

    &__metatags {
      margin-top: 7px;
      display: flex;
      flex-wrap: wrap;
      height: 80px;
      max-height: 100px;
      overflow: hidden;
      align-content: flex-start;

      &-item {
        background: rgba(255, 255, 255, 0.397);
        padding: 2px 6px;
        border-radius: 7px;
        margin-right: 5px;
        margin-bottom: 5px;
        max-width: 90px;
        text-overflow: ellipsis;
        word-wrap: unset;
        overflow-x: hidden;
        font-size: 12px;
        height: 22px;
      }
    }

    &__btn {
      position: absolute;
    }

    &__btn-delete {
      width: 17px;
      height: 17px;
      overflow: hidden;
      border: none;
      bottom: 5px;
      right: 8px;
      background: unset;
      content: " ";
      background-image: url(../assets/delete.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__btn-update {
      bottom: 0px;
      left: 8px;
      cursor: pointer;
    }

    &__btn-details {
      width: 10px;
      height: 10px;
      bottom: 8px;
      right: 59px;
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    .KO-list {
      width: 200px;

      &__item-body {
        height: 130px;
      }

      &__metatags {
        height: 70px;
        max-height: 90px;
      }
    }
  }

  @media (max-width: 992px) {
    .KO-list {
      width: 180px;

      &__item-body {
        height: 120px;
      }

      &__metatags {
        height: 60px;
        max-height: 80px;
      }
    }
  }

  @media (max-width: 768px) {
    .KO-list {
      width: 160px;

      &__item-body {
        height: 100px;
      }

      &__metatags {
        height: 50px;
        max-height: 70px;
      }
    }
  }

  @media (max-width: 768px) {
    .KO-list {
      width: 140px;

      &__item-body {
        height: 90px;
      }

      &__metatags {
        height: 40px;
        max-height: 60px;
      }
    }
  }
</style>
