<template>
  <div class="KO-details">
    <hr />
    <div class="KO-details__body">
      {{ body }}
    </div>
    <hr v-if="metatags.length" />
    <ul class="KO_details__hashtags">
      <li
        class="KO_details__hashtags-item"
        v-for="hash in metatags"
        :key="hash"
      >
        {{ hash }}
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
  export default {
    name: "KODetails",
    props: {
      body: String,
      metatags: Array,
    },
  };
</script>

<style lang="scss">
  .KO-details {
    &__body {
      padding: 10px;
      min-height: 100px;
      max-height: 40vh;
      overflow-y: auto;
      word-wrap: break-word;
    }

    ul {
      display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 20vh;

      li {
        background: rgba(150, 150, 150, 0.397);
        padding: 2px 6px;
        border-radius: 7px;
        margin-right: 5px;
        margin-bottom: 5px;
        word-wrap: unset;
        font-size: 12px;
        height: 22px;
      }
    }

    hr {
      margin: 5px;
    }
  }
</style>
