<template>
  <div class="background">
    <section class="main-container">
      <aside class="branding">
        <img src="../../assets/logo.png" alt="logo" class="logo" />
        <h2 class="title">LocusVMS</h2>
        <p class="description short">Venue Management System</p>
        <p class="description long">
          A powerful, yet easy-to-use application for managing venue geospatial
          data.
        </p>
      </aside>
      <section class="form-container">
        <h3 class="form-title">Reset Password</h3>
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <label for="reset-code">Reset Code</label>
            <input
              id="reset-code"
              type="text"
              class="input-field"
              v-model="resetCode"
            />
          </div>
          <div class="form-group">
            <label for="new-password">New Password</label>
            <input
              id="new-password"
              type="password"
              data-type="password"
              class="input-field"
              v-model="newPassword"
            />
          </div>
          <button
            type="submit"
            class="submit-button"
            @click="onSubmit"
            :disabled="loading"
          >
            <div v-if="loading" class="loading-spinner"></div>
            <span v-else>Submit</span>
          </button>
          <div v-if="showError" class="error">
            {{ errmsg }}
          </div>
        </form>
        <div class="divider"></div>
      </section>
    </section>
  </div>
</template>

<script>
  import { router } from "../../router/index";
  import * as config from "../../config";
  const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
  var userPool = [];

  export default {
    name: "ResetPassword",
    data() {
      return {
        loading: false,
        resetCode: "",
        newPassword: "",
        showError: false,
        errcode: "",
        errmsg: "",
      };
    },
    methods: {
      onSubmit() {
        userPool = new AmazonCognitoIdentity.CognitoUserPool(config.poolData);
        const username = this.$store.state.username;
        const userData = {
          Username: username,
          Pool: userPool,
        };

        if (username === undefined) {
          this.showError = true;
          this.errmsg = "Username is not exist. Please try again resend code";
          return;
        }

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        this.showError = false;
        this.errcode = "";

        this.loading = true;

        cognitoUser.confirmPassword(this.resetCode, this.newPassword, {
          onSuccess: (data) => {
            this.loading = false;
            console.log("forgot password confirmed: " + JSON.stringify(data));
            router.push("/sign-in");
          },
          onFailure: (err) => {
            this.loading = false;
            var code = JSON.stringify(err.code);
            console.log("forgot password confirm error: " + code);
            this.showError = true;
            this.errcode = code;
          },
        });
      },
    },
    watch: {
      errcode() {
        console.log("watched error code: " + this.errcode);
        if (this.errcode !== "") {
          if (this.errcode === '"CodeMismatchException"') {
            this.errmsg = "Invalid verification code provided";
          } else if (this.errcode === '"NotAuthorizedException"') {
            this.errmsg = "The user has already been confirmed";
          } else if (this.errcode === '"UserNotFoundException"') {
            this.errmsg = "Username email not found!";
          } else if (this.errcode === '"LimitExceededException"') {
            this.errmsg = "Attempt limit exceeded, please try after some time";
          } else if (this.errcode === '"UserNotConfirmedException"') {
            this.errmsg = "User registration not confirmed";
          } else {
            this.errmsg = "An error has occurred!";
          }
          this.showError = true;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  $primary-color: #355242;
  $secondary-color: #1e2142;
  $background-gradient-start: #fdf9f6;
  $background-gradient-end: $primary-color;
  $branding-background-start: rgb(73, 138, 175);
  $branding-background-end: $secondary-color;

  .background {
    background: radial-gradient(
      circle farthest-corner at 100px 50px,
      $background-gradient-start,
      $background-gradient-end
    );
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-container {
    display: flex;
    width: 70%;
    height: 80%;
    box-shadow: 0 22px 30px 20px rgba(0, 0, 0, 0.24),
      0 37px 60px 10px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
  }

  .branding {
    width: 30%;
    background: radial-gradient(
      circle farthest-corner at 50px 100px,
      $branding-background-start,
      $branding-background-end
    );
    border-radius: 5px 0 0 5px;
    padding: 20%;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 80px;
    }

    .title {
      margin-top: 5px;
      font-size: 34px;
    }

    .description {
      width: 260px;
      margin-top: 5px;

      &.short {
        margin-top: 0;
      }

      &.long {
        margin-top: 50px;
      }
    }
  }

  .form-container {
    width: 70%;
    padding: 20px;
    border-radius: 0 5px 5px 0;
    background: radial-gradient(
      circle farthest-corner at 100px 50px,
      #d1d1d1,
      #4c4750
    );

    .form-title {
      margin-top: 30px;
      font-size: 24px;
      margin-bottom: 20px;
      color: white;
    }

    .form-group {
      margin-bottom: 15px;

      .input-field {
        width: 100%;
        text-decoration: circle;
        border: none;
        padding: 10px;
        border-radius: 25px;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .submit-button {
      width: 100%;
      padding: 10px 20px;
      border-radius: 20px;
      background: #1161ee;
      color: white;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .divider {
      height: 2px;
      background: rgba(0, 0, 0, 0.2);
      margin: 40px 0;
    }
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    /* Color of the spinner */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 1200px) {
    .branding {
      .logo {
        width: 75px;
      }

      .title {
        margin-top: 5px;
        font-size: 30px;
      }

      .description {
        width: 220px;
        margin-top: 5px;

        &.short {
          margin-top: 0;
        }

        &.long {
          margin-top: 40px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .main-container {
      flex-direction: column;
    }
    .branding {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      height: 12%;
      padding: 0%;
      border-top-right-radius: 0.3em;
      border-top-left-radius: 0.3em;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .logo {
        width: 3em;
        margin: 0px;
      }

      .title {
        margin: 0px;
        font-size: 30px;
      }

      .description {
        width: 280px;
        margin: 0px;
        &.short {
          margin-top: 0;
        }

        &.long {
          margin-top: 0px;
          display: none;
        }
      }
    }

    .form-container {
      width: 100%;
      height: 90%;
      padding: 20px 100px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0.3em;
      border-bottom-left-radius: 0.3em;
      background: radial-gradient(
        circle farthest-corner at 100px 50px,
        #d1d1d1,
        #4c4750
      );

      .form-title {
        margin-top: 30px;
        font-size: 24px;
        margin-bottom: 20px;
        color: white;
      }

      .form-group {
        margin-bottom: 15px;

        .input-field {
          width: 100%;
          text-decoration: circle;
          border: none;
          padding: 10px;
          border-radius: 25px;
          background: rgba(255, 255, 255, 0.1);
        }
      }

      .submit-button {
        width: 100%;
        padding: 10px 20px;
        border-radius: 20px;
        background: #1161ee;
        color: white;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .divider {
        height: 2px;
        background: rgba(0, 0, 0, 0.2);
        margin: 40px 0;
      }
    }
  }

  @media (max-width: 768px) {
    .branding {
      .logo {
        width: 2.5em;
        margin-left: 15px;
      }

      .title {
        font-size: 1.5em;
        margin-left: 10px;
      }

      .description {
        &.short {
          font-size: 0.9em;
        }
      }
    }

    .form-container {
      padding: 20px 80px;
    }
  }

  @media (max-width: 576px) {
    .main-container {
      width: 80%;
      height: 90%;
    }
    .branding {
      justify-content: start;
      height: 10%;
      .logo {
        width: 2em;
        margin-left: 15px;
      }

      .title {
        font-size: 1.3em;
        margin-left: 10px;
      }

      .description {
        &.short {
          display: none;
        }
      }
    }

    .form-container {
      padding: 20px 40px;

      .form-title {
        font-size: 1.2em;
      }

      .form-group {
        font-size: 0.8em;

        .input-field {
          font-size: 0.7em;
        }
      }

      .submit-button {
        font-size: 0.7em;
      }
    }
  }
</style>
