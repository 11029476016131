import { setItemToLS, getItemFromLS } from "../functions";
import axios from "../axios";

export const state = () => ({
  newThought: {},
  isSaveThoughtSuccess: false,
  looseThoughts: [],
  stateOverlayForLT: true,
  currentThoughtId: ""
});
export const getters = {
  newThought: (s) => s.newThought,
  isSaveThoughtSuccess: (s) => s.isSaveThoughtSuccess,
  looseThoughts: (s) => s.looseThoughts,
  stateOverlayForLT: (s) => s.stateOverlayForLT,
  currentThoughtId: (s) => s.currentThoughtId
};
export const mutations = {
  setNewThought(state, newThought) {
    state.newThought = newThought;
  },
  setIsSaveThoughtSuccess(state) {
    state.isSaveThoughtSuccess = true;
    setTimeout(() => {
      state.isSaveThoughtSuccess = false;
    }, 3000);
  },
  setLooseThought(state, newLooseThoughts) {
    state.looseThoughts = newLooseThoughts;
  },
  setStateOverlayForLT(state, payload) {
    state.stateOverlayForLT = payload;
  },
  setCurrentThoughtId(state, id){
    state.currentThoughtId = id;
  }
};
export const actions = {
  async saveNewThought({ commit, dispatch }) {
    const newThought = getItemFromLS("newThought");
    setItemToLS("newThought", {});
    commit("setStateOverlayForLT", true);
    axios
      .post("main/knowledge_object", newThought)
      .then(() => {
        commit("setIsSaveThoughtSuccess");
        dispatch("getLooseThoughts");
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteLooseThought({ commit, dispatch }, payload) {
    commit("setStateOverlayForLT", true);
    axios
      .delete(`main/knowledge_object/${payload}`)
      .then(() => {
        dispatch("getLooseThoughts");
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async getLooseThoughts({ commit }) {
    commit("setStateOverlayForLT", true);
    await axios
      .get("main/user/loose_thoughts")
      .then((res) => {
        commit("setLooseThought", res.data.body);
        commit("setStateOverlayForLT", false);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
