<template>
  <nav class="main-nav">
    <div class="nav-container">
      <div class="main-nav__title">Synapse Knowledge Manager</div>
      <button @click="logout" class="main-nav__logout-button">Logout</button>
    </div>
    <div v-if="!isLoading" class="main-container">
      <ul class="main-nav__list">
        <li class="main-nav__list-item">
          <router-link to="/blank-canvas" class="main-nav__list-link"
            >Blank Canvas</router-link
          >
        </li>
        <li class="main-nav__list-item">
          <router-link to="/loose-thoughts" class="main-nav__list-link"
            >Loose Thoughts</router-link
          >
        </li>
      </ul>
      <ul class="previous-canvases__list">
        <li
          v-for="previousCanvas in canvases"
          :key="previousCanvas.userCanvasId"
          class="previous-canvases__list-item"
          @click="openPreviousCanvas(previousCanvas.userCanvasId)"
        >
          <div>{{ previousCanvas.canvasName }}</div>
        </li>
      </ul>
    </div>
    <div v-else class="main-nav__spinner">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getItemFromLS } from "../functions";

  export default {
    name: "MainMenu",
    data() {
      return {
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters({
        canvases: "canvasStore/canvases",
        token: "authStore/token",
      }),
      isLoggedIn() {
        return this.$store.getters.isAuthenticated;
      },
    },
    beforeMount() {
      if (getItemFromLS("access_token")) {
        this.$store.dispatch("canvasStore/getCanvases");
      }
    },
    watch: {
      token() {
        this.$store.dispatch("canvasStore/getCanvases");
      },
      canvases() {
        this.isLoading = false;
      },
    },
    methods: {
      openPreviousCanvas(id) {
        this.$router.push(`/previous-canvas/${id}`);
      },
      logout() {
        this.$store.dispatch("authStore/logout");
        this.$router.push({ path: "/sign-in" });
      },
    },
  };
</script>

<style scoped lang="scss">
  .nav-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
  }

  .main-container {
    display: flex;
    width: 95%;
    justify-content: space-around;
    align-items: center;
  }

  .main-nav__title {
    color: white;
    font-size: 44px;
  }

  .main-nav__logout-button {
    margin-top: 30px;
    background-color: #4a5568;
    color: #f7fafc;
    border: 2px solid #a0aec0;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    height: 48px;
  }

  .main-nav__logout-button:hover {
    background-color: #2d3748;
    color: #e2e8f0;
    border-color: #718096;
  }

  .main-nav {
    background: radial-gradient(
      circle farthest-corner at 100px 50px,
      #c7defc,
      #23382c
    );
    min-height: 100vh;
  }

  .main-nav__list {
    min-height: 400px;
    height: 70vh;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 16px;
    align-items: center;
  }

  .main-nav__list-item {
    width: 250px;
    height: 230px;
    background: radial-gradient(
      circle farthest-corner at 50px 100px,
      rgb(73, 138, 175),
      #244770
    );
    text-align: center;
    padding: 45px 30px;
    border-radius: 15px;
    font-size: 26px;

    transition: 0.15s;
    margin: 0em 2em;
  }

  .main-nav__list-item:hover {
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24),
      0 8px 20px 0 rgba(0, 0, 0, 0.19);
    background: radial-gradient(
      circle farthest-corner at 50px 100px,
      rgb(101, 168, 207),
      #2d599b
    );
    transform: scale(1.05);
    cursor: pointer;
  }

  .main-nav__list-link {
    color: rgb(243, 243, 243);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .previous-canvases__list {
    display: flex;
    flex-wrap: wrap;
    width: 264px;
    max-height: 360px;
    overflow-x: hidden;
    padding: 15px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #4e695f;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(12, 24, 94, 0.514);
    }
  }

  .previous-canvases__list-item {
    width: 100px;
    height: 100px;
    background: radial-gradient(
      circle farthest-corner at 50px 100px,
      rgb(171, 189, 199),
      #366b99
    );
    margin: 10px 0px;
    border-radius: 10px;
    padding: 5px 5px;
    transition: 0.15s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .previous-canvases__list-item:hover {
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24),
      0 8px 20px 0 rgba(0, 0, 0, 0.19);
    background: radial-gradient(
      circle farthest-corner at 50px 100px,
      rgb(171, 189, 199),
      #234f75
    );
    transform: scale(1.08);
    color: white;
  }

  .previous-canvases__list-item p {
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    max-height: 80px;
    padding: 5px;
    text-align: center;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-line-clamp: 3;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .previous-canvases__list-item:nth-child(2n + 1) {
    margin-right: 20px;
  }

  .previous-canvases__list-item:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .main-nav__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding-bottom: 150px;
  }

  @media (max-width: 1200px) {
    .main-nav__title {
      font-size: 38px;
    }

    .main-nav__list-item {
      width: 200px;
      height: 180px;
      font-size: 1.5em;
    }

    .main-nav__logout-button {
      padding: 8px 16px;
      font-size: 0.9rem;
      height: 40px;
    }

    .previous-canvases__list-item {
      width: 90px;
      height: 90px;
      font-size: 0.9em;
    }
  }

  @media (max-width: 992px) {
    .main-nav__title {
      font-size: 34px;
    }

    .main-nav__list-item {
      width: 180px;
      height: 160px;
      font-size: 1.4em;
      margin: 0em 1em;
    }

    .previous-canvases__list-item {
      width: 90px;
      height: 90px;
      font-size: 0.9em;
    }
  }

  @media (max-width: 768px) {
    .main-container {
      justify-content: space-between;
    }
    .main-nav__title {
      font-size: 30px;
    }

    .main-nav__list {
      flex-direction: column;
      justify-content: center;
      margin-left: 3em;
    }

    .main-nav__list-item {
      width: 160px;
      height: 140px;
      font-size: 1.2em;
      margin: 1em 0em;
    }

    .previous-canvases__list-item {
      width: 90px;
      height: 90px;
      font-size: 0.9em;
    }
  }

  @media (max-width: 576px) {
    .main-container {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    .main-nav__list {
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      height: 8vh;
      min-height: 180px;
      margin: 0;
      padding: 0;
    }

    .main-nav__list-item {
      width: 160px;
      height: 80px;
      font-size: 1em;
    }

    .nav-container {
      padding: 30px 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .main-nav__title {
      font-size: 25px;
      padding-top: 0px;
      line-height: 30px;
    }

    .main-nav__logout-button {
      margin: 0;
    }

    .previous-canvases__list {
      width: 100%;
      justify-content: center;
      max-height: 240px;
    }

    .previous-canvases__list-item {
      width: 80px;
      height: 80px;
      font-size: 0.8em;
      margin-left: 0.5em;
    }

    .previous-canvases__list-item:nth-child(2n + 1) {
      margin-right: 0px;
    }

    .main-nav__logout-button {
      height: 35px;
      font-size: 0.8em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
