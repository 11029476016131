<template>
  <div class="background-sign-in">
    <div class="sign-in">
      <div class="sign-in__left-part">
        <img
          src="../../assets/logo.png"
          alt="logo"
          width="80"
          class="sign-in__left-part-logo"
        />
        <div class="sign-in__left-part-title">LocusVMS</div>
        <p class="sign-in__left-part-text-short">Venue Management System</p>
        <p class="sign-in__left-part-text-long">
          A powerful, yet easy-to-use application for managing venue geospatial data.
        </p>
      </div>
      <div class="sign-in__right-part">
        <div class="login-wrap">
          <div class="login-html">
            <input id="tab-1" type="radio" name="tab" class="sign-in" checked />
            <label for="tab-1" class="tab">Sign In</label>
            <input id="tab-2" type="radio" name="tab" class="sign-up" />
            <label for="tab-2" class="tab">Sign Up</label>
            <div class="login-form">
              <div class="sign-in-htm">
                <div class="group">
                  <label for="email-login" class="label">Email</label>
                  <input
                    id="email-login"
                    type="text"
                    class="input"
                    v-model="signIn.username"
                  />
                </div>
                <div class="group">
                  <label for="pass-login" class="label">Password</label>
                  <input
                    id="pass-login"
                    type="password"
                    class="input"
                    data-type="password"
                    v-model="signIn.password"
                  />
                </div>
                <div class="group">
                  <input
                    id="check"
                    type="checkbox"
                    class="check"
                    v-model="signIn.keepSignedIn"
                  />
                  <label for="check" class="check-label">
                    <input type="checkbox" class="check-label-box" checked />
                    <div>Keep me Signed in</div>
                  </label>
                </div>

                <div class="group">
                  <a class="button" @click="onSubmitSignIn" :disabled="loading">
                    <div v-if="loading" class="loading-spinner"></div>
                    <span v-else>Sign In</span>
                  </a>
                </div>
                <div v-if="showLoginError" class="error">
                  {{ errmsg }}
                </div>
                <div class="hr"></div>
                <div class="foot-lnk">
                  <a class="button" @click="onNavigateForgotPassword"
                    >Forgot Password?</a
                  >
                </div>
              </div>
              <div class="sign-up-htm">
                <div class="group">
                  <label for="email" class="label">Email Address</label>
                  <input
                    id="email"
                    type="text"
                    class="input"
                    v-model="signUp.email"
                    @input="handleInputChange"
                  />
                  <p v-if="signUp.email && emailValidate()" class="error">
                    Email is not valid
                  </p>
                </div>
                <div class="group">
                  <label for="user-register" class="label">Username</label>
                  <input
                    id="user-register"
                    type="text"
                    class="input"
                    v-model="signUp.username"
                    @input="handleInputChange"
                  />
                </div>
                <div class="group">
                  <label for="pass-register" class="label">Password</label>
                  <input
                    id="pass-register"
                    type="password"
                    class="input"
                    data-type="password"
                    v-model="signUp.password"
                    @input="handleInputChange"
                  />
                  <p v-if="signUp.password && passwordValidate()" class="error">
                    Password must be at least 8 characters
                  </p>
                </div>
                <div class="group">
                  <label for="repeat-pass" class="label">Repeat Password</label>
                  <input
                    id="repeat-pass"
                    type="password"
                    class="input"
                    data-type="password"
                    v-model="signUp.confirmPassword"
                    @input="handleInputChange"
                  />
                  <p
                    v-if="
                      signUp.password &&
                      signUp.confirmPassword &&
                      !passwordsMatch()
                    "
                    class="error"
                  >
                    Passwords do not match.
                  </p>
                </div>

                <div class="group">
                  <button
                    type="submit"
                    class="button"
                    @click="onSubmitSignUp"
                    :disabled="loading || !isFormValid()"
                  >
                    <div v-if="loading" class="loading-spinner"></div>
                    <span v-else>Sign Up</span>
                  </button>
                </div>
                <div v-if="showRegisterError" class="error">
                  {{ errmsg }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { router } from "../../router/index";
  import * as config from "../../config";
  var AmazonCognitoIdentity = require("amazon-cognito-identity-js");

  var userPool = [];
  var attributeList = [];
  var dataEmail = {
    Name: "email",
    Value: "",
  };

  export default {
    name: "SignIn",
    data() {
      return {
        signIn: {
          username: "",
          password: "",
          keepSignedIn: true,
        },
        signUp: {
          username: "",
          password: "",
          confirmPassword: "",
          email: "",
        },
        showRegisterError: false,
        showLoginError: false,
        errcode: "",
        errmsg: "",
        valid: false,
        emailRules: [
          (v) => !!v || "E-mail is required",
          // eslint-disable-next-line
          (v) =>
            // eslint-disable-next-line no-useless-escape
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        passRules: [
          (v) => !!v || "Password is required",
          (v) => v.length >= 8 || "Password must be at least 8 characters",
        ],
        loading: false,
      };
    },
    computed: {
      isEmailValid() {
        return !this.emailValidate();
      },
      isPasswordValid() {
        return !this.passwordValidate();
      },
      doPasswordsMatch() {
        return this.passwordsMatch();
      },
    },
    watch: {
      errcode() {
        console.log("watched error code: " + this.errcode);
      },
    },
    methods: {
      isFormValid() {
        return (
          this.passwordsMatch() &&
          !this.emailValidate() &&
          !this.passwordValidate()
        );
      },
      passwordsMatch() {
        return this.signUp.password === this.signUp.confirmPassword;
      },
      emailValidate() {
        return this.emailRules.find((rule) => rule(this.signUp.email) !== true);
      },
      passwordValidate() {
        return this.passRules.find(
          (rule) => rule(this.signUp.password) !== true
        );
      },
      handleInputChange() {
        this.showRegisterError = false;
      },
      onNavigateForgotPassword() {
        router.push("/forgot-password");
      },
      onSubmitSignUp() {

        if(!config.adminEmailList.includes(this.signUp.email)){
          this.showRegisterError = true
          this.errmsg = "The email has not been approved by the admin."
          return
        }

        this.errmsg = ""

        dataEmail.Value = this.signUp.email;
        const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
          dataEmail
        );
        attributeList.push(attributeEmail);

        console.log("attribute list: " + attributeList);
        userPool = new AmazonCognitoIdentity.CognitoUserPool(config.poolData);

        console.log(
          "sign up with: " + this.signUp.email + " " + this.signUp.password
        );

        this.errcode = "";

        const signUpParams = {
          username: this.signUp.email,
          password: this.signUp.password,
          email: this.signUp.email,
        };

        this.loading = true;

        userPool.signUp(
          signUpParams.username,
          signUpParams.password,
          attributeList,
          null,
          (err, result) => {
            this.loading = false;
            this.showRegisterError = false;
            console.log("register callback");

            if (err) {
              console.log("register failed: " + JSON.stringify(err));
              console.log("err message ===>", err.message);
              this.errcode = JSON.stringify(err.code);
              this.showRegisterError = true;
              this.errmsg = err.message;
            } else {
              console.log("registration success: " + JSON.stringify(result));
              const userId = result.userSub;

              const userData = {
                username: this.signUp.username,
                password: this.signUp.password,
                email: this.signUp.email,
                userId: userId,
              };

              this.$store.commit("authStore/updateState", {
                userData: userData,
              });
              console.log("user name is " + result.user.getUsername());
              router.push("/confirm-email");
            }
          }
        );
      },
      onSubmitSignIn() {
        const authenticationData = {
          Username: this.signIn.username,
          Password: this.signIn.password,
        };
        const authenticationDetails =
          new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        const userPool = new AmazonCognitoIdentity.CognitoUserPool(
          config.poolData
        );

        const userData = {
          Username: this.signIn.username,
          Pool: userPool,
        };

        this.loading = true;
        this.showLoginError = false;

        const userAuth = new AmazonCognitoIdentity.CognitoUser(userData);
        userAuth.authenticateUser(authenticationDetails, {
          onSuccess: async (session) => {
            this.loading = false;

            const accessToken = session.getAccessToken().getJwtToken();
            const idToken = session.getIdToken().getJwtToken();
            const refreshToken = session.getRefreshToken().getToken();

            this.$store.dispatch("authStore/setTokens", {
              accessToken,
              idToken,
              refreshToken,
            });

            router.push("/");
          },
          onFailure: (err) => {
            if (!this.callback) {
              this.loading = false;
              this.showLoginError = true;
              console.log("sign in failure =====>", err.message);
              this.errmsg = err.message;
              this.errcode = JSON.stringify(err.code);
              console.log("error ===>", err.code);
            }
          },
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .background-sign-in {
    background: radial-gradient(
      circle farthest-corner at 100px 50px,
      #fdf9f6,
      #355242
    );
    width: 100%;
    height: 100vh;
    display: flex;

    .sign-in {
      display: flex;
      width: 70%;
      height: 80%;
      margin: auto;
      box-shadow: 0 22px 30px 20px rgba(0, 0, 0, 0.24),
        0 37px 60px 10px rgba(0, 0, 0, 0.19);
      border-radius: 5px;

      &__left-part {
        width: 35%;
        background: radial-gradient(
          circle farthest-corner at 50px 100px,
          rgb(73, 138, 175),
          #1e2142
        );
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding-top: 20%;
        color: rgb(211, 211, 211);
      }
    }
  }

  .sign-in__left-part-title {
    margin-top: 5px;
    font-size: 34px;
    color: white;
  }

  .sign-in__left-part-text-short {
    margin: 5px auto 0;
    width: 250px;
  }

  .sign-in__left-part-text-long {
    margin: 50px auto 0;
    width: 260px;
  }

  .sign-in__right-part {
    width: 65%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  .clearfix:after,
  .clearfix:before {
    content: "";
    display: table;
  }

  .clearfix:after {
    clear: both;
    display: block;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .check-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      margin-left: 10px;
    }
  }

  .login-wrap {
    width: 100%;
    margin: auto;
    min-height: 100%;
    position: relative;
  }

  .login-html {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 50px 90px 40px;
    background: radial-gradient(
      circle farthest-corner at 100px 50px,
      #d1d1d1,
      #4c4750
    );
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .login-html .sign-in-htm,
  .login-html .sign-up-htm {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    transition: all 0.4s linear;
  }

  .login-html .sign-in,
  .login-html .sign-up,
  .login-form .group .check {
    display: none;
  }

  .login-html .tab,
  .login-form .group .label,
  .login-form .group .button {
    text-transform: uppercase;
  }

  .login-html .tab {
    font-size: 22px;
    margin-right: 15px;
    padding-bottom: 5px;
    margin: 0 15px 10px 0;
    display: inline-block;
    border-bottom: 2px solid transparent;
  }

  .login-html .sign-in:checked + .tab,
  .login-html .sign-up:checked + .tab {
    color: #fff;
    border-color: #1161ee;
  }

  .login-form {
    min-height: 345px;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
  }

  .login-form .group {
    margin-bottom: 15px;
  }

  .login-form .group .label,
  .login-form .group .input,
  .login-form .group .button {
    width: 100%;
    color: #fff;
    display: block;
  }

  .login-form .group .input,
  .login-form .group .button {
    border: none;
    padding: 15px 20px;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.1);
  }

  .login-form .group input[data-type="password"] {
    text-decoration: circle;
    -webkit-text-security: circle;
  }

  .login-form .group .label {
    color: rgb(255, 255, 255);
    font-size: 12px;
  }

  .login-form .group .button {
    background: #1161ee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .login-form .group label .icon {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    position: relative;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
  }

  .login-form .group label .icon:before,
  .login-form .group label .icon:after {
    content: "";
    width: 10px;
    height: 2px;
    background: #fff;
    position: absolute;
    transition: all 0.2s ease-in-out 0s;
  }

  .login-form .group label .icon:before {
    left: 3px;
    width: 5px;
    bottom: 6px;
    transform: scale(0) rotate(0);
  }

  .login-form .group label .icon:after {
    top: 6px;
    right: 0;
    transform: scale(0) rotate(0);
  }

  .login-form .group .check:checked + label {
    color: #fff;
  }

  .login-form .group .check:checked + label .icon {
    background: #1161ee;
  }

  .login-form .group .check:checked + label .icon:before {
    transform: scale(1) rotate(45deg);
  }

  .login-form .group .check:checked + label .icon:after {
    transform: scale(1) rotate(-45deg);
  }

  .login-html
    .sign-in:checked
    + .tab
    + .sign-up
    + .tab
    + .login-form
    .sign-in-htm {
    transform: rotate(0);
  }

  .login-html .sign-up:checked + .tab + .login-form .sign-up-htm {
    transform: rotate(0);
  }

  .hr {
    height: 2px;
    margin: 60px 0 50px 0;
    background: rgba(255, 255, 255, 0.2);
  }

  .foot-lnk {
    text-align: center;
    cursor: pointer;
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    /* Color of the spinner */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 1200px) {
    .sign-in__left-part {
      padding-top: 15%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .sign-in__left-part-title {
      margin-top: 0px;
      font-size: 28px;
    }

    .sign-in__left-part-text-short {
      margin: 10px 10px 10px 10px;
      
      font-size: 16px;
      width: 280px;
    }

    .sign-in__left-part-text-long {
      margin: 10px;
      padding: 20px;
      width: 280px;
      font-size: 16px;
    }

    .login-html {
      padding: 80px 100px 40px;
    }
  }

  @media (max-width: 992px) {
    .sign-in {
      display: flex;
      flex-direction: column;

      .sign-in__left-part {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-top: 0%;
        height: 12%;
        justify-content: space-around;

        border-top-right-radius: 0.3em;
        border-top-left-radius: 0.3em;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .sign-in__left-part-text-long {
          display: none;
        }

        .sign-in__left-part-logo {
          width: 3em;
        }
      }
    }

    .sign-in__right-part {
      width: 100%;
      height: 100%;
    }

    .login-html {
      padding: 2em 5em;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0.3em;
      border-bottom-left-radius: 0.3em;
    }
  }

  @media (max-width: 768px) {
    .sign-in {
      .sign-in__left-part {
        height: 10%;
        justify-content: space-evenly;
        padding-left: 1em;
        .sign-in__left-part-title {
          font-size: 1.5em;
          padding-left: 0.5em;
        }

        .sign-in__left-part-text-short {
          font-size: 0.9em;
        }

        .sign-in__left-part-logo {
          width: 2.5em;
        }
      }
    }

    .login-form .group .label {
      font-size: 0.7em;
    }

    .login-form .group .input {
      border: none;
      padding: 0.5em 1em;
      border-radius: 25px;
      font-size: 1em;
      background: rgba(255, 255, 255, 0.1);
    }

    .login-form .group .button {
      border: none;
      padding: 0.5em 0.5em;
      border-radius: 25px;
      font-size: 1em;
      background: #1161ee;
      margin-top: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .login-html .tab {
      font-size: 1.3em;
    }
  }

  @media (max-width: 576px) {
    .background-sign-in {
      .sign-in {
        width: 80%;
        height: 90%;
        .sign-in__left-part {
          height: 9%;
          justify-content: start;

          .sign-in__left-part-title {
            font-size: 1.2em;
          }

          .sign-in__left-part-text-short {
            display: none;
          }

          .sign-in__left-part-logo {
            width: 2em;
          }
        }
      }
    }

    .login-html {
      padding: 2em 3em;
    }

    .login-html .tab {
      font-size: 1em;
    }

    .login-form .group .label {
      font-size: 0.6em;
    }

    .login-form {
      margin-top: 1em;
    }

    .login-form .group .input {
      border: none;
      padding: 0.5em 1em;
      border-radius: 25px;
      font-size: 0.7em;
      background: rgba(255, 255, 255, 0.1);
    }

    .login-form .group .button {
      border: none;
      padding: 0.5em 0.5em;
      border-radius: 25px;
      font-size: 0.7em;
      background: #1161ee;
      margin-top: 5em;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .check-label {
      input {
        width: 0.8em;
        height: 0.8em;
      }

      div {
        font-size: 0.8em;
      }
    }

    .foot-lnk {
      font-size: 0.8em;
    }
  }

  @media (max-width: 320px) {
    .login-html .tab {
      font-size: 0.8em;
    }

    .login-form .group .input {
      font-size: 0.5em;
    }
  }
</style>
